.hero-section {
  height: 100vh;
}

.hero-section .hero-image {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.hero-image {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-image1 {
  color: #FFF;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  font-family: Cloudsters;
  font-size: 142px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.26px;
  align-items: end;
  padding-top: 80px;
}

.studio {
  color: #FFF;
  font-family: Montserrat;
  font-size: 76px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 20px;
}

.hero-image2 span {
  background: linear-gradient(90deg, #EEA026 73.69%, #E93A27 85.05%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Cloudsters;
  font-size: 142px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.26px;
}

.hero-image2 {
  flex-direction: column-reverse !important;
  padding-bottom: 105px;
  padding-left: 20px;
}

.undermain {
  color: #FFF;
  font-family: Montserrat !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.buoton {
  display: inline-flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(103deg, #EEA026 2.43%, #E93A27 61%);
  color: #FFF;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.72px;
  width: fit-content;
}

.undermaindiv {
  position: absolute;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  gap: 20px;

}

.anothersectionpeop {
  height: 100vh;
  background-image: url("../Images/people.jpg");
  background-size: cover;
}

::-webkit-scrollbar {
  display: none;
}

.bklogotext {
  color: #FFF;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.24px;
}

.maintosecteion {
  padding: 90px 30px;
  align-items: center;
  gap: 15px;
}

.maintosecteion img {
  width: 220px;
}

.colortags {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #EE9F26;
  color: #FFF;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  position: absolute;
}

.colortags1 {
  left: 30%;
}

.colortags2 {
  left: 60%;
  margin-top: 100px;
  background: #F03D29;


}

.colortags3 {
  left: 10%;
  margin-top: 22vh;

}

.colortags4 {
  left: 50%;
  margin-top: 38vh;
  background: #F06129;

}

.colortags5 {
  left: 20%;
  margin-top: 50vh;
  background: #F03D29;



}

























/* animation o load */

/* 
@keyframes wave {

  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }

  25% {
    transform: translateY(-20px) rotate(-10deg);
  }

  50% {
    transform: translateY(0) rotate(0deg);
  }

  75% {
    transform: translateY(-20px) rotate(10deg);
  }
} */

.loading-screen {
  overflow: hidden;
}


.dghkgjkf {
  z-index: 3;
  padding: 40px;
}

.maincolorf {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #B3D658;
}
.maincolorf5 {
  background-color: #a8a8a8;
}

.maincolorf:nth-child(3) {
  background-color: #2C55ED;
}

.maincolorf:nth-child(4) {

  background-color: #E0076A;
}
.fgjkdfgjk{
  color: #fff;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfnghdhbf {
  padding: 22px;
  background-color: #fff;
  position: relative;
  top: 90vh;
}

.gkhlkfghfg{
  color: #fff;
  margin-right: 25px;
}
.hchdfc {
  font-size: 25px;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .deskdid {
    display: none;
  }
}
/* .about-faq-section {
  padding: 0px 0px 0px 25%;
} */

.about-text {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  /* margin-top: -40px; */
}

.gifimageplac video {
  width: 988px;
  height: 329px;
  object-fit: cover;
  margin-top: 30px;

}

.about-faq-textf {
  max-width: 988px;
  margin-top: 20px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;

}

.w-998 {
  width: 988px;
}

.text-wrapper-2sdfj {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: left;
  width: 988px;


}

.FaMinus {
  background-color: gray;
  color: #fff;
  border-radius: 20px;
  font-size: 30px;
  padding: 2px;

}

.FaMinus1 {
  background-color: gray;
  color: #fff;
  border-radius: 20px;
  font-size: 30px;
  padding: 2px;

}

.FaMinus2 {
  background-color: gray;
  color: #fff;
  border-radius: 20px;
  font-size: 30px;
  padding: 2px;

}

.circlebefore {
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}

.mainadanfskjndfjsdjf {
  /* width: 958px; */
}

.mainimagein-faq {
  height: 500px;
}

.maingrrtjk {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 39.01px;
  text-align: left;
  color: #747474;
  margin: 20px 0px 20px 0px;
}

.mainupertext {
  width: 970px;
}

.underfssbd {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
  text-align: left;
  color: #DC096A !important;
  margin: 0 20px;



}
.underfssbd a{
  color: #DC096A !important;

}

.boxundefawq {
  width: 455px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 20px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 2px 0px 0px 0px;
  opacity: 0px;
  background: #FFFFFF;
  border: 2px solid;
  padding: 8px;
  border-image-source: linear-gradient(180deg, #E0E0E0 0%, #CECECE 100%);
  box-shadow: 4px 8px 4px 0px #00000040;


}

.boxundefawq span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  cursor: pointer;

}

.boxundefawq svg {
  margin-left: 5px;

}

.mainimagfgfgein-faq {
  height: 140px;
}

.text-styles {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #747474;

}

.about-faq-section-new {
  padding: 0% 10% 0px 25%;
}

.bkblue {
  width: -webkit-fill-available;

}

.bluetitel {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #2857EF;

}

.mb-9 {
  margin-bottom: 120px;
}

.bluebigobutton {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  letter-spacing: 0.03em;
  text-align: center;
  width: Fixed (1, 067px)px;
  height: Hug (99px)px;
  padding: 30px 10px 30px 10px;
  gap: 10px;
  border-radius: 20px;
  background: #2857EF;
  color: #fff;

}

.text-styles span {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: left;

}

.rotate-icon {
  transition: transform 0.3s ease-in-out;
  transform: rotate(90deg);
}

.main-textpink {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  color: #DB086B;
  cursor: pointer;

}

.sfdhktghbderf {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
  color: #747474;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mainsvg {
  margin-right: 10px;
}

.sgdfgdg {
  width: 33.3%;
}

.brand-logo-case {
  height: 20rem;
}

.w-20 {
  width: 20%;
}

.ml-3 {
  padding-left: 42px;
  width: max-content;
  color: #747474 !important;
}

.jhjhgjh {
  margin-bottom: 50px;
}

.fghdjkfgjdg {
  width: -webkit-fill-available;
}

.backgroundimagedf {
  background-image: url('https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  height: 600px;
  background-size: cover;

}

.sfghshjdj {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  height: 600px;

}

.dfkkkghfehgb {
  margin-top: 15px;
  font-size: 100px;
  font-weight: 800;
  margin-right: 10px;
}

.glassblack {
  /* From https://css.glass */
  background: rgba(143, 140, 140, 0.63);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: auto;

}

.form_id {
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
}

.inputrir {
  border-right: none;
  border-bottom: none;
  border-top: none;
  width: 70%;
  position: absolute;
  right: 15px;
  padding-left: 15px;
}

.fjgkdfbtn {
  color: #fff !important;
  background-color: #EF5C28;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  cursor: pointer;
}

.main-bodyfront .mainheadder {
  position: absolute;
  padding: 0px 0px 0px 50px;
}

.main-bodyfront .hamburger span {
  /* width: 40px;
  height: 4px;
  position: relative; */
  top: 24px;
  left: 30px;
  margin: 10px 0;
}

.main-bodyfront .menuToggle {
  display: block !important;
  padding: 0;
}

.brandstasrt {
  /* background-color: #000; */


}

.kfgjhdfgkjhdg {
  position: absolute;
  width: -webkit-fill-available;
}
.fkjgmngfdgb a{
  /* background-color: #2638d9 !important; */
  height:7rem;
  /* z-index: 2; */
  width: 25rem;
  display: flex;
  /* position: absolute; */
  flex-direction: column
}
.fkjgmngfdgb{
  position: absolute;
    z-index: 2;
}

a {
  text-decoration: none;
  color: #000;
}



































































































































.fdgjdkfgfdgkhdbgd {
  background: #1B2234;
  height: 100vh;
}

.fdgjdkfgkhdbgd {
  background: #000;
  height: 100vh;
}

.particle {
  position: absolute;
  border-radius: 50%;
}

/* @-webkit-keyframes particle-animation-1 {
  100% {
    transform: translate3d(64vw, 19vh, 60px);
  }
}

@keyframes particle-animation-1 {
  100% {
    transform: translate3d(74vw, 19vh, 60px);
  }
}
.particle:nth-child(1) {
  -webkit-animation: particle-animation-1 20s infinite;
          animation: particle-animation-1 20s infinite;
  opacity: 6.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  transform: translate3d(56vw, 34vh, 63px);
  background: #c7d926;
}

@-webkit-keyframes particle-animation-2 {
  100% {
    transform: translate3d(75vw, 73vh, 54px);
  }
}

@keyframes particle-animation-2 {
  100% {
    transform: translate3d(75vw, 73vh, 54px);
  }
}
.particle:nth-child(2) {
  -webkit-animation: particle-animation-2 20s infinite;
          animation: particle-animation-2 20s infinite;
  opacity: 3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  transform: translate3d(56vw, 45vh, 71px);
  background: #d9af26;
}

@-webkit-keyframes particle-animation-3 {
  100% {
    transform: translate3d(10vw, 39vh, 59px);
  }
}

@keyframes particle-animation-3 {
  100% {
    transform: translate3d(10vw, 39vh, 59px);
  }
}
.particle:nth-child(3) {
  -webkit-animation: particle-animation-3 20s infinite;
          animation: particle-animation-3 20s infinite;
  opacity: 0.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  transform: translate3d(16vw, 86vh, 72px);
  background: #a926d9;
}

@-webkit-keyframes particle-animation-4 {
  100% {
    transform: translate3d(16vw, 79vh, 24px);
  }
}

@keyframes particle-animation-4 {
  100% {
    transform: translate3d(16vw, 79vh, 24px);
  }
}
.particle:nth-child(4) {
  -webkit-animation: particle-animation-4 20s infinite;
          animation: particle-animation-4 20s infinite;
  opacity: 9.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  transform: translate3d(4vw, 50vh, 40px);
  background: #d9ac26;
}

@-webkit-keyframes particle-animation-5 {
  100% {
    transform: translate3d(54vw, 25vh, 31px);
  }
}

@keyframes particle-animation-5 {
  100% {
    transform: translate3d(54vw, 25vh, 31px);
  }
}
.particle:nth-child(5) {
  -webkit-animation: particle-animation-5 20s infinite;
          animation: particle-animation-5 20s infinite;
  opacity: 3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  transform: translate3d(73vw, 9vh, 69px);
  background: #d9bb26;
}

@-webkit-keyframes particle-animation-6 {
  100% {
    transform: translate3d(22vw, 3vh, 13px);
  }
}

@keyframes particle-animation-6 {
  100% {
    transform: translate3d(22vw, 3vh, 13px);
  }
}
.particle:nth-child(6) {
  -webkit-animation: particle-animation-6 20s infinite;
          animation: particle-animation-6 20s infinite;
  opacity: 6.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  transform: translate3d(45vw, 32vh, 58px);
  background: #2688d9;
}

@-webkit-keyframes particle-animation-7 {
  100% {
    transform: translate3d(10vw, 16vh, 48px);
  }
}

@keyframes particle-animation-7 {
  100% {
    transform: translate3d(10vw, 16vh, 48px);
  }
}
.particle:nth-child(7) {
  -webkit-animation: particle-animation-7 20s infinite;
          animation: particle-animation-7 20s infinite;
  opacity: 9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  transform: translate3d(39vw, 30vh, 14px);
  background: #267dd9;
}

@-webkit-keyframes particle-animation-8 {
  100% {
    transform: translate3d(52vw, 78vh, 46px);
  }
}

@keyframes particle-animation-8 {
  100% {
    transform: translate3d(52vw, 78vh, 46px);
  }
}
.particle:nth-child(8) {
  -webkit-animation: particle-animation-8 20s infinite;
          animation: particle-animation-8 20s infinite;
  opacity: 1.6;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  transform: translate3d(31vw, 55vh, 42px);
  background: #26d9af;
}

@-webkit-keyframes particle-animation-9 {
  100% {
    transform: translate3d(77vw, 11vh, 90px);
  }
}

@keyframes particle-animation-9 {
  100% {
    transform: translate3d(77vw, 11vh, 90px);
  }
}
.particle:nth-child(9) {
  -webkit-animation: particle-animation-9 20s infinite;
          animation: particle-animation-9 20s infinite;
  opacity: 3.9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  transform: translate3d(44vw, 7vh, 51px);
  background: #d92626;
}

@-webkit-keyframes particle-animation-10 {
  100% {
    transform: translate3d(85vw, 61vh, 3px);
  }
}

@keyframes particle-animation-10 {
  100% {
    transform: translate3d(85vw, 61vh, 3px);
  }
}
.particle:nth-child(10) {
  -webkit-animation: particle-animation-10 20s infinite;
          animation: particle-animation-10 20s infinite;
  opacity: 6.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  transform: translate3d(19vw, 43vh, 77px);
  background: #d92662;
}

@-webkit-keyframes particle-animation-11 {
  100% {
    transform: translate3d(3vw, 61vh, 92px);
  }
}

@keyframes particle-animation-11 {
  100% {
    transform: translate3d(3vw, 61vh, 92px);
  }
}
.particle:nth-child(11) {
  -webkit-animation: particle-animation-11 20s infinite;
          animation: particle-animation-11 20s infinite;
  opacity: 3.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  transform: translate3d(49vw, 43vh, 90px);
  background: #d926d3;
}

@-webkit-keyframes particle-animation-12 {
  100% {
    transform: translate3d(74vw, 11vh, 83px);
  }
}

@keyframes particle-animation-12 {
  100% {
    transform: translate3d(74vw, 11vh, 83px);
  }
}
.particle:nth-child(12) {
  -webkit-animation: particle-animation-12 20s infinite;
          animation: particle-animation-12 20s infinite;
  opacity: 5.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  transform: translate3d(50vw, 90vh, 55px);
  background: #4726d9;
}

@-webkit-keyframes particle-animation-13 {
  100% {
    transform: translate3d(13vw, 56vh, 81px);
  }
}

@keyframes particle-animation-13 {
  100% {
    transform: translate3d(13vw, 56vh, 81px);
  }
}
.particle:nth-child(13) {
  -webkit-animation: particle-animation-13 20s infinite;
          animation: particle-animation-13 20s infinite;
  opacity: 5.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  transform: translate3d(63vw, 37vh, 26px);
  background: #26d97a;
}

@-webkit-keyframes particle-animation-14 {
  100% {
    transform: translate3d(68vw, 25vh, 10px);
  }
}

@keyframes particle-animation-14 {
  100% {
    transform: translate3d(68vw, 25vh, 10px);
  }
}
.particle:nth-child(14) {
  -webkit-animation: particle-animation-14 20s infinite;
          animation: particle-animation-14 20s infinite;
  opacity: 3.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  transform: translate3d(26vw, 45vh, 74px);
  background: #2638d9;
}

@-webkit-keyframes particle-animation-15 {
  100% {
    transform: translate3d(87vw, 58vh, 1px);
  }
}

@keyframes particle-animation-15 {
  100% {
    transform: translate3d(87vw, 58vh, 1px);
  }
}
.particle:nth-child(15) {
  -webkit-animation: particle-animation-15 20s infinite;
          animation: particle-animation-15 20s infinite;
  opacity: 9.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  transform: translate3d(67vw, 16vh, 39px);
  background: #2653d9;
}

@-webkit-keyframes particle-animation-16 {
  100% {
    transform: translate3d(21vw, 13vh, 98px);
  }
}

@keyframes particle-animation-16 {
  100% {
    transform: translate3d(21vw, 13vh, 98px);
  }
}
.particle:nth-child(16) {
  -webkit-animation: particle-animation-16 20s infinite;
          animation: particle-animation-16 20s infinite;
  opacity: 9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  transform: translate3d(36vw, 73vh, 32px);
  background: #d9268b;
}

@-webkit-keyframes particle-animation-17 {
  100% {
    transform: translate3d(4vw, 69vh, 80px);
  }
}

@keyframes particle-animation-17 {
  100% {
    transform: translate3d(4vw, 69vh, 80px);
  }
}
.particle:nth-child(17) {
  -webkit-animation: particle-animation-17 20s infinite;
          animation: particle-animation-17 20s infinite;
  opacity: 2.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  transform: translate3d(44vw, 18vh, 89px);
  background: #26a9d9;
}

@-webkit-keyframes particle-animation-18 {
  100% {
    transform: translate3d(54vw, 77vh, 80px);
  }
}

@keyframes particle-animation-18 {
  100% {
    transform: translate3d(54vw, 77vh, 80px);
  }
}
.particle:nth-child(18) {
  -webkit-animation: particle-animation-18 20s infinite;
          animation: particle-animation-18 20s infinite;
  opacity: 9.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  transform: translate3d(16vw, 53vh, 8px);
  background: #2656d9;
}

@-webkit-keyframes particle-animation-19 {
  100% {
    transform: translate3d(19vw, 60vh, 91px);
  }
}

@keyframes particle-animation-19 {
  100% {
    transform: translate3d(19vw, 60vh, 91px);
  }
}
.particle:nth-child(19) {
  -webkit-animation: particle-animation-19 20s infinite;
          animation: particle-animation-19 20s infinite;
  opacity: 5;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  transform: translate3d(62vw, 67vh, 100px);
  background: #8e26d9;
}

@-webkit-keyframes particle-animation-20 {
  100% {
    transform: translate3d(59vw, 20vh, 31px);
  }
}

@keyframes particle-animation-20 {
  100% {
    transform: translate3d(59vw, 20vh, 31px);
  }
}
.particle:nth-child(20) {
  -webkit-animation: particle-animation-20 20s infinite;
          animation: particle-animation-20 20s infinite;
  opacity: 5.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  transform: translate3d(73vw, 51vh, 69px);
  background: #d3d926;
}

@-webkit-keyframes particle-animation-21 {
  100% {
    transform: translate3d(76vw, 40vh, 80px);
  }
}

@keyframes particle-animation-21 {
  100% {
    transform: translate3d(76vw, 40vh, 80px);
  }
}
.particle:nth-child(21) {
  -webkit-animation: particle-animation-21 20s infinite;
          animation: particle-animation-21 20s infinite;
  opacity: 5.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -4.2s;
          animation-delay: -4.2s;
  transform: translate3d(47vw, 60vh, 81px);
  background: #3526d9;
}

@-webkit-keyframes particle-animation-22 {
  100% {
    transform: translate3d(1vw, 89vh, 38px);
  }
}

@keyframes particle-animation-22 {
  100% {
    transform: translate3d(1vw, 89vh, 38px);
  }
}
.particle:nth-child(22) {
  -webkit-animation: particle-animation-22 20s infinite;
          animation: particle-animation-22 20s infinite;
  opacity: 4.7;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -4.4s;
          animation-delay: -4.4s;
  transform: translate3d(43vw, 12vh, 96px);
  background: #26d977;
}

@-webkit-keyframes particle-animation-23 {
  100% {
    transform: translate3d(48vw, 70vh, 89px);
  }
}

@keyframes particle-animation-23 {
  100% {
    transform: translate3d(48vw, 70vh, 89px);
  }
}
.particle:nth-child(23) {
  -webkit-animation: particle-animation-23 20s infinite;
          animation: particle-animation-23 20s infinite;
  opacity: 2.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
  transform: translate3d(9vw, 73vh, 8px);
  background: #b526d9;
}

@-webkit-keyframes particle-animation-24 {
  100% {
    transform: translate3d(65vw, 69vh, 61px);
  }
}

@keyframes particle-animation-24 {
  100% {
    transform: translate3d(65vw, 69vh, 61px);
  }
}
.particle:nth-child(24) {
  -webkit-animation: particle-animation-24 20s infinite;
          animation: particle-animation-24 20s infinite;
  opacity: 0.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -4.8s;
          animation-delay: -4.8s;
  transform: translate3d(71vw, 34vh, 22px);
  background: #d97426;
}

@-webkit-keyframes particle-animation-25 {
  100% {
    transform: translate3d(38vw, 64vh, 74px);
  }
}

@keyframes particle-animation-25 {
  100% {
    transform: translate3d(38vw, 64vh, 74px);
  }
}
.particle:nth-child(25) {
  -webkit-animation: particle-animation-25 20s infinite;
          animation: particle-animation-25 20s infinite;
  opacity: 6.6;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  transform: translate3d(41vw, 49vh, 57px);
  background: #26a6d9;
}

@-webkit-keyframes particle-animation-26 {
  100% {
    transform: translate3d(1vw, 27vh, 40px);
  }
}

@keyframes particle-animation-26 {
  100% {
    transform: translate3d(1vw, 27vh, 40px);
  }
}
.particle:nth-child(26) {
  -webkit-animation: particle-animation-26 20s infinite;
          animation: particle-animation-26 20s infinite;
  opacity: 2.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -5.2s;
          animation-delay: -5.2s;
  transform: translate3d(14vw, 41vh, 57px);
  background: #d9be26;
}

@-webkit-keyframes particle-animation-27 {
  100% {
    transform: translate3d(56vw, 23vh, 85px);
  }
}

@keyframes particle-animation-27 {
  100% {
    transform: translate3d(56vw, 23vh, 85px);
  }
}
.particle:nth-child(27) {
  -webkit-animation: particle-animation-27 20s infinite;
          animation: particle-animation-27 20s infinite;
  opacity: 6.6;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -5.4s;
          animation-delay: -5.4s;
  transform: translate3d(47vw, 31vh, 30px);
  background: #9726d9;
}

@-webkit-keyframes particle-animation-28 {
  100% {
    transform: translate3d(34vw, 34vh, 94px);
  }
}

@keyframes particle-animation-28 {
  100% {
    transform: translate3d(34vw, 34vh, 94px);
  }
}
.particle:nth-child(28) {
  -webkit-animation: particle-animation-28 20s infinite;
          animation: particle-animation-28 20s infinite;
  opacity: 8.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -5.6s;
          animation-delay: -5.6s;
  transform: translate3d(88vw, 6vh, 32px);
  background: #26d99a;
}

@-webkit-keyframes particle-animation-29 {
  100% {
    transform: translate3d(58vw, 17vh, 97px);
  }
}

@keyframes particle-animation-29 {
  100% {
    transform: translate3d(58vw, 17vh, 97px);
  }
}
.particle:nth-child(29) {
  -webkit-animation: particle-animation-29 20s infinite;
          animation: particle-animation-29 20s infinite;
  opacity: 9.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -5.8s;
          animation-delay: -5.8s;
  transform: translate3d(71vw, 87vh, 13px);
  background: #26d9be;
}

@-webkit-keyframes particle-animation-30 {
  100% {
    transform: translate3d(19vw, 23vh, 81px);
  }
}

@keyframes particle-animation-30 {
  100% {
    transform: translate3d(19vw, 23vh, 81px);
  }
}
.particle:nth-child(30) {
  -webkit-animation: particle-animation-30 20s infinite;
          animation: particle-animation-30 20s infinite;
  opacity: 0.9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
  transform: translate3d(72vw, 45vh, 22px);
  background: #be26d9;
}

@-webkit-keyframes particle-animation-31 {
  100% {
    transform: translate3d(62vw, 17vh, 22px);
  }
}

@keyframes particle-animation-31 {
  100% {
    transform: translate3d(62vw, 17vh, 22px);
  }
}
.particle:nth-child(31) {
  -webkit-animation: particle-animation-31 20s infinite;
          animation: particle-animation-31 20s infinite;
  opacity: 7.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -6.2s;
          animation-delay: -6.2s;
  transform: translate3d(47vw, 50vh, 35px);
  background: #35d926;
}

@-webkit-keyframes particle-animation-32 {
  100% {
    transform: translate3d(65vw, 33vh, 72px);
  }
}

@keyframes particle-animation-32 {
  100% {
    transform: translate3d(65vw, 33vh, 72px);
  }
}
.particle:nth-child(32) {
  -webkit-animation: particle-animation-32 20s infinite;
          animation: particle-animation-32 20s infinite;
  opacity: 3.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -6.4s;
          animation-delay: -6.4s;
  transform: translate3d(81vw, 43vh, 68px);
  background: #26d959;
}

@-webkit-keyframes particle-animation-33 {
  100% {
    transform: translate3d(45vw, 27vh, 49px);
  }
}

@keyframes particle-animation-33 {
  100% {
    transform: translate3d(45vw, 27vh, 49px);
  }
}
.particle:nth-child(33) {
  -webkit-animation: particle-animation-33 20s infinite;
          animation: particle-animation-33 20s infinite;
  opacity: 1.7;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -6.6s;
          animation-delay: -6.6s;
  transform: translate3d(7vw, 77vh, 6px);
  background: #268bd9;
}

@-webkit-keyframes particle-animation-34 {
  100% {
    transform: translate3d(52vw, 56vh, 20px);
  }
}

@keyframes particle-animation-34 {
  100% {
    transform: translate3d(52vw, 56vh, 20px);
  }
}
.particle:nth-child(34) {
  -webkit-animation: particle-animation-34 20s infinite;
          animation: particle-animation-34 20s infinite;
  opacity: 9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -6.8s;
          animation-delay: -6.8s;
  transform: translate3d(41vw, 37vh, 39px);
  background: #26d0d9;
}

@-webkit-keyframes particle-animation-35 {
  100% {
    transform: translate3d(34vw, 43vh, 73px);
  }
}

@keyframes particle-animation-35 {
  100% {
    transform: translate3d(34vw, 43vh, 73px);
  }
}
.particle:nth-child(35) {
  -webkit-animation: particle-animation-35 20s infinite;
          animation: particle-animation-35 20s infinite;
  opacity: 5;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -7s;
          animation-delay: -7s;
  transform: translate3d(39vw, 5vh, 90px);
  background: #a926d9;
}

@-webkit-keyframes particle-animation-36 {
  100% {
    transform: translate3d(85vw, 17vh, 46px);
  }
}

@keyframes particle-animation-36 {
  100% {
    transform: translate3d(85vw, 17vh, 46px);
  }
}
.particle:nth-child(36) {
  -webkit-animation: particle-animation-36 20s infinite;
          animation: particle-animation-36 20s infinite;
  opacity: 0.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -7.2s;
          animation-delay: -7.2s;
  transform: translate3d(11vw, 4vh, 89px);
  background: #56d926;
}

@-webkit-keyframes particle-animation-37 {
  100% {
    transform: translate3d(87vw, 32vh, 78px);
  }
}

@keyframes particle-animation-37 {
  100% {
    transform: translate3d(87vw, 32vh, 78px);
  }
}
.particle:nth-child(37) {
  -webkit-animation: particle-animation-37 20s infinite;
          animation: particle-animation-37 20s infinite;
  opacity: 9.7;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -7.4s;
          animation-delay: -7.4s;
  transform: translate3d(1vw, 9vh, 37px);
  background: #2668d9;
}

@-webkit-keyframes particle-animation-38 {
  100% {
    transform: translate3d(16vw, 22vh, 2px);
  }
}

@keyframes particle-animation-38 {
  100% {
    transform: translate3d(16vw, 22vh, 2px);
  }
}
.particle:nth-child(38) {
  -webkit-animation: particle-animation-38 20s infinite;
          animation: particle-animation-38 20s infinite;
  opacity: 7.9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -7.6s;
          animation-delay: -7.6s;
  transform: translate3d(63vw, 6vh, 67px);
  background: #5f26d9;
}

@-webkit-keyframes particle-animation-39 {
  100% {
    transform: translate3d(66vw, 67vh, 73px);
  }
}

@keyframes particle-animation-39 {
  100% {
    transform: translate3d(66vw, 67vh, 73px);
  }
}
.particle:nth-child(39) {
  -webkit-animation: particle-animation-39 20s infinite;
          animation: particle-animation-39 20s infinite;
  opacity: 5.6;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -7.8s;
          animation-delay: -7.8s;
  transform: translate3d(21vw, 71vh, 81px);
  background: #d92688;
}

@-webkit-keyframes particle-animation-40 {
  100% {
    transform: translate3d(83vw, 52vh, 78px);
  }
}

@keyframes particle-animation-40 {
  100% {
    transform: translate3d(83vw, 52vh, 78px);
  }
}
.particle:nth-child(40) {
  -webkit-animation: particle-animation-40 20s infinite;
          animation: particle-animation-40 20s infinite;
  opacity: 1.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
  transform: translate3d(46vw, 48vh, 16px);
  background: #65d926;
}

@-webkit-keyframes particle-animation-41 {
  100% {
    transform: translate3d(7vw, 71vh, 15px);
  }
}

@keyframes particle-animation-41 {
  100% {
    transform: translate3d(7vw, 71vh, 15px);
  }
}
.particle:nth-child(41) {
  -webkit-animation: particle-animation-41 20s infinite;
          animation: particle-animation-41 20s infinite;
  opacity: 10;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -8.2s;
          animation-delay: -8.2s;
  transform: translate3d(64vw, 39vh, 22px);
  background: #38d926;
}

@-webkit-keyframes particle-animation-42 {
  100% {
    transform: translate3d(70vw, 7vh, 59px);
  }
}

@keyframes particle-animation-42 {
  100% {
    transform: translate3d(70vw, 7vh, 59px);
  }
}
.particle:nth-child(42) {
  -webkit-animation: particle-animation-42 20s infinite;
          animation: particle-animation-42 20s infinite;
  opacity: 7.8;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -8.4s;
          animation-delay: -8.4s;
  transform: translate3d(74vw, 7vh, 73px);
  background: #6ed926;
}

@-webkit-keyframes particle-animation-43 {
  100% {
    transform: translate3d(22vw, 36vh, 68px);
  }
}

@keyframes particle-animation-43 {
  100% {
    transform: translate3d(22vw, 36vh, 68px);
  }
}
.particle:nth-child(43) {
  -webkit-animation: particle-animation-43 20s infinite;
          animation: particle-animation-43 20s infinite;
  opacity: 3.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -8.6s;
          animation-delay: -8.6s;
  transform: translate3d(61vw, 7vh, 9px);
  background: #94d926;
}

@-webkit-keyframes particle-animation-44 {
  100% {
    transform: translate3d(9vw, 21vh, 64px);
  }
}

@keyframes particle-animation-44 {
  100% {
    transform: translate3d(9vw, 21vh, 64px);
  }
}
.particle:nth-child(44) {
  -webkit-animation: particle-animation-44 20s infinite;
          animation: particle-animation-44 20s infinite;
  opacity: 1.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -8.8s;
          animation-delay: -8.8s;
  transform: translate3d(68vw, 66vh, 50px);
  background: #ca26d9;
}

@-webkit-keyframes particle-animation-45 {
  100% {
    transform: translate3d(25vw, 71vh, 59px);
  }
}

@keyframes particle-animation-45 {
  100% {
    transform: translate3d(25vw, 71vh, 59px);
  }
}
.particle:nth-child(45) {
  -webkit-animation: particle-animation-45 20s infinite;
          animation: particle-animation-45 20s infinite;
  opacity: 3.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -9s;
          animation-delay: -9s;
  transform: translate3d(9vw, 44vh, 75px);
  background: #264dd9;
}

@-webkit-keyframes particle-animation-46 {
  100% {
    transform: translate3d(12vw, 64vh, 97px);
  }
}

@keyframes particle-animation-46 {
  100% {
    transform: translate3d(12vw, 64vh, 97px);
  }
}
.particle:nth-child(46) {
  -webkit-animation: particle-animation-46 20s infinite;
          animation: particle-animation-46 20s infinite;
  opacity: 5.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -9.2s;
          animation-delay: -9.2s;
  transform: translate3d(83vw, 24vh, 19px);
  background: #6226d9;
}

@-webkit-keyframes particle-animation-47 {
  100% {
    transform: translate3d(1vw, 9vh, 13px);
  }
}

@keyframes particle-animation-47 {
  100% {
    transform: translate3d(1vw, 9vh, 13px);
  }
}
.particle:nth-child(47) {
  -webkit-animation: particle-animation-47 20s infinite;
          animation: particle-animation-47 20s infinite;
  opacity: 4.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -9.4s;
          animation-delay: -9.4s;
  transform: translate3d(11vw, 43vh, 16px);
  background: #d92680;
}

@-webkit-keyframes particle-animation-48 {
  100% {
    transform: translate3d(79vw, 15vh, 44px);
  }
}

@keyframes particle-animation-48 {
  100% {
    transform: translate3d(79vw, 15vh, 44px);
  }
}
.particle:nth-child(48) {
  -webkit-animation: particle-animation-48 20s infinite;
          animation: particle-animation-48 20s infinite;
  opacity: 2.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -9.6s;
          animation-delay: -9.6s;
  transform: translate3d(84vw, 8vh, 19px);
  background: #d93b26;
}

@-webkit-keyframes particle-animation-49 {
  100% {
    transform: translate3d(73vw, 57vh, 6px);
  }
}

@keyframes particle-animation-49 {
  100% {
    transform: translate3d(73vw, 57vh, 6px);
  }
}
.particle:nth-child(49) {
  -webkit-animation: particle-animation-49 20s infinite;
          animation: particle-animation-49 20s infinite;
  opacity: 9.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -9.8s;
          animation-delay: -9.8s;
  transform: translate3d(16vw, 71vh, 47px);
  background: #26d97a;
}

@-webkit-keyframes particle-animation-50 {
  100% {
    transform: translate3d(15vw, 32vh, 70px);
  }
}

@keyframes particle-animation-50 {
  100% {
    transform: translate3d(15vw, 32vh, 70px);
  }
}
.particle:nth-child(50) {
  -webkit-animation: particle-animation-50 20s infinite;
          animation: particle-animation-50 20s infinite;
  opacity: 6.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
  transform: translate3d(32vw, 9vh, 72px);
  background: #26d994;
}

@-webkit-keyframes particle-animation-51 {
  100% {
    transform: translate3d(67vw, 18vh, 91px);
  }
}

@keyframes particle-animation-51 {
  100% {
    transform: translate3d(67vw, 18vh, 91px);
  }
}
.particle:nth-child(51) {
  -webkit-animation: particle-animation-51 20s infinite;
          animation: particle-animation-51 20s infinite;
  opacity: 3.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -10.2s;
          animation-delay: -10.2s;
  transform: translate3d(12vw, 33vh, 64px);
  background: #7126d9;
}

@-webkit-keyframes particle-animation-52 {
  100% {
    transform: translate3d(56vw, 23vh, 74px);
  }
}

@keyframes particle-animation-52 {
  100% {
    transform: translate3d(56vw, 23vh, 74px);
  }
}
.particle:nth-child(52) {
  -webkit-animation: particle-animation-52 20s infinite;
          animation: particle-animation-52 20s infinite;
  opacity: 1.2;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -10.4s;
          animation-delay: -10.4s;
  transform: translate3d(76vw, 68vh, 29px);
  background: #a626d9;
}

@-webkit-keyframes particle-animation-53 {
  100% {
    transform: translate3d(8vw, 74vh, 98px);
  }
}

@keyframes particle-animation-53 {
  100% {
    transform: translate3d(8vw, 74vh, 98px);
  }
}
.particle:nth-child(53) {
  -webkit-animation: particle-animation-53 20s infinite;
          animation: particle-animation-53 20s infinite;
  opacity: 5.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -10.6s;
          animation-delay: -10.6s;
  transform: translate3d(74vw, 65vh, 25px);
  background: #d9d926;
}

@-webkit-keyframes particle-animation-54 {
  100% {
    transform: translate3d(41vw, 4vh, 87px);
  }
}

@keyframes particle-animation-54 {
  100% {
    transform: translate3d(41vw, 4vh, 87px);
  }
}
.particle:nth-child(54) {
  -webkit-animation: particle-animation-54 20s infinite;
          animation: particle-animation-54 20s infinite;
  opacity: 5.3;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -10.8s;
          animation-delay: -10.8s;
  transform: translate3d(70vw, 11vh, 86px);
  background: #d99426;
}

@-webkit-keyframes particle-animation-55 {
  100% {
    transform: translate3d(80vw, 7vh, 42px);
  }
}

@keyframes particle-animation-55 {
  100% {
    transform: translate3d(80vw, 7vh, 42px);
  }
}
.particle:nth-child(55) {
  -webkit-animation: particle-animation-55 20s infinite;
          animation: particle-animation-55 20s infinite;
  opacity: 4.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -11s;
          animation-delay: -11s;
  transform: translate3d(87vw, 52vh, 19px);
  background: #26d9b8;
}

@-webkit-keyframes particle-animation-56 {
  100% {
    transform: translate3d(4vw, 13vh, 85px);
  }
}

@keyframes particle-animation-56 {
  100% {
    transform: translate3d(4vw, 13vh, 85px);
  }
}
.particle:nth-child(56) {
  -webkit-animation: particle-animation-56 20s infinite;
          animation: particle-animation-56 20s infinite;
  opacity: 0.9;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -11.2s;
          animation-delay: -11.2s;
  transform: translate3d(17vw, 3vh, 79px);
  background: #4426d9;
}

@-webkit-keyframes particle-animation-57 {
  100% {
    transform: translate3d(12vw, 49vh, 15px);
  }
}

@keyframes particle-animation-57 {
  100% {
    transform: translate3d(12vw, 49vh, 15px);
  }
}
.particle:nth-child(57) {
  -webkit-animation: particle-animation-57 20s infinite;
          animation: particle-animation-57 20s infinite;
  opacity: 1.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -11.4s;
          animation-delay: -11.4s;
  transform: translate3d(66vw, 88vh, 28px);
  background: #d92665;
}

@-webkit-keyframes particle-animation-58 {
  100% {
    transform: translate3d(69vw, 31vh, 21px);
  }
}

@keyframes particle-animation-58 {
  100% {
    transform: translate3d(69vw, 31vh, 21px);
  }
}
.particle:nth-child(58) {
  -webkit-animation: particle-animation-58 20s infinite;
          animation: particle-animation-58 20s infinite;
  opacity: 4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -11.6s;
          animation-delay: -11.6s;
  transform: translate3d(90vw, 23vh, 7px);
  background: #2644d9;
}

@-webkit-keyframes particle-animation-59 {
  100% {
    transform: translate3d(71vw, 73vh, 65px);
  }
}

@keyframes particle-animation-59 {
  100% {
    transform: translate3d(71vw, 73vh, 65px);
  }
}
.particle:nth-child(59) {
  -webkit-animation: particle-animation-59 20s infinite;
          animation: particle-animation-59 20s infinite;
  opacity: 2.4;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -11.8s;
          animation-delay: -11.8s;
  transform: translate3d(53vw, 22vh, 91px);
  background: #d0d926;
}

@-webkit-keyframes particle-animation-60 {
  100% {
    transform: translate3d(42vw, 85vh, 84px);
  }
}

@keyframes particle-animation-60 {
  100% {
    transform: translate3d(42vw, 85vh, 84px);
  }
}
.particle:nth-child(60) {
  -webkit-animation: particle-animation-60 20s infinite;
          animation: particle-animation-60 20s infinite;
  opacity: 0.1;
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -12s;
          animation-delay: -12s;
  transform: translate3d(39vw, 14vh, 74px);
  background: #d99a26;
} */

canvas {
  position: absolute;
  top: auto !important;
  left: 0;
  pointer-events: none;
  z-index: 0;
}

.ghfghfghfg {
  width: 350px;
  height: 200px;
  position: absolute;
  z-index: 9999;
  right: 50%;
  color: #fff;
  margin-top: -210px;
  margin-left: -150px;
  border-radius: 20px;
  display: flex;
  box-shadow: 
    0 4px 10px rgba(0, 0, 0, 0.3),
    inset 0 -4px 10px rgba(0, 0, 0, 0.2); 
}
.ghfghfghfg::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid var(--popup-bg-color);
  transform: rotate(3.142rad);
  top: 98%;
  left: 41%;
}
.imageofpop{
  background-color: #fff;
  border-radius:10px ;
}
.dfgf{
  margin-bottom: 5px;
  margin-top: 7px;
}

.close-buttonfhbf {
  position: absolute;
  float: left;
  margin-left: 330px;
  width: 30px;
  margin-top: -10px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;

}
.sddmmdfsds{
  padding: 20px;
}
.sddmmdfsds img{
  width: 90px;
}









.button-container {
  display: flex;
margin-top: -90px;
  position: absolute;
}
.fdgfngmfm{
  margin-left: 120px;
}
.fdgfngmfm1{
  margin-left: 110px;
}
.fdgfngmfm2{
  margin-left: 300px;
}
.fdgfngmfm3{
  margin-left: 110px;
}

.button-wrapper {
  position: relative;
}

.hover-button {
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 50px;
  color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hover-button:hover {
  background-color: transparent;
  border-radius: 50px;
}

.popup {
  position: absolute;
  bottom: -150px;
  left: 80%;
  transform: translateX(-50%);
  background-color: white !important;
  z-index: 99;
  width: 250px;
  padding: 10px;
  border: 2px solid black;
  display: none;
  text-align: left; /* Center the content horizontally */
}

.button-wrapper:hover .popup {
  display: block;
}
.flkgflsgdf{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
}








.gifimageplac video {
  display: block;
  border-radius: 8px; /* Optional: Rounded corners for the video */
}

.overlay-content {
  position: absolute;
  top: 35%; /* Position at the center of the video container */
  left: 40%;
  transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
  text-align: center;
  z-index: 2; /* Ensure it's above the video */
  color: white; /* Text color */
}

.overlay-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.white-line {
  width: 40%;
  height: 10px;
  background-color: white;
  margin-bottom: 15px;
}















.fghfghfghfghfg a {
  color: #2638d9 !important;
}
.fghfghfghfghfg svg {
  fill: #2638d9 !important;
}








.sliding-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  height: 100vh;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(-150px);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.sliding-header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sliding-header nav ul li {
  padding: 15px;
  display: flex;
  align-items: center;
}

.sliding-header nav ul li svg {
  margin-right: 10px;
}

.menuToggle.open ~ .sliding-header {
  transform: translateX(0);
}
.fgdfkgldkfg{
  background-color: #000;
  z-index: 999;
}
.w-20{
  width: 20%;
}
.w-20 img{
  width: 300px;
  height: 300px;
}



